<div class="flex flex-row items-center" [ngClass]="isFullScreen()">
  <div class="filterPanel flex flex-col pap-4">
    <div class="top-box flex flex-row justify-between ml-6 mr-6">
      <div class="title">Filter & Sort</div>
      <p-button
        [label]="getFilterButtonTitle()"
        [rounded]="true"
        severity="secondary"
        size="small"
        (onClick)="clear()"
        [disabled]="disableClearBtn"
      ></p-button>
    </div>
    <div class="main-part flex flex-col gap-6">
      <div class="sort-part">
        <div class="title mt-4">Sort</div>
        <div class="sort-radios flex flex-col gap-4">
          <div *ngFor="let filter of filters" class="field-checkbox">
            <p-radioButton
              class="radioButton"
              [inputId]="filter.value"
              name="category"
              [value]="filter"
              [(ngModel)]="selectedFilter"
              (ngModelChange)="sortMethod(filter.value)"
            />
            <label
              [for]="filter.value"
              class="ml-2 text-base font-medium lineheight-150"
            >
              {{ filter.label }}
            </label>
          </div>
        </div>
      </div>
      <div class="price-part flex flex-col gap-4">
        <div class="title">Price range</div>
        <div class="price-description">
          Product prices before taxes and fees.
        </div>

        <ngx-slider
          [(value)]="minSelected"
          [(highValue)]="maxSelected"
          [options]="sliderOptions()"
          (valueChange)="selectPrice()"
        ></ngx-slider>
        <div
          class="maxprice-part w-full flex flex-row items-center justify-between gap-2"
        >
          <div>
            <div class="value-label">Min</div>
            <p-inputGroup>
              <p-inputGroupAddon>$</p-inputGroupAddon>
              <p-inputNumber
                [style]="{ width: '96px' }"
                [(ngModel)]="minSelected"
                inputId="minmaxfraction"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
              />
            </p-inputGroup>
          </div>
          <span class="mt-4"> - </span>
          <div>
            <div class="value-label">Max</div>
            <p-inputGroup>
              <p-inputGroupAddon>$</p-inputGroupAddon>
              <!-- <input type="text" placeholder="10.00" /> -->
              <p-inputNumber
                [style]="{ width: '96px' }"
                [(ngModel)]="maxSelected"
                inputId="minmaxfraction"
                mode="decimal"
                [minFractionDigits]="2"
                [maxFractionDigits]="2"
              />
            </p-inputGroup>
          </div>
        </div>
      </div>
      <div class="category-part">
        <div class="title mb-6">Category</div>
        <div class="flex flex-col gap-2">
          @for (
            item of searchProductStore.filteredCategoryAndCounts();
            track item;
            let i = $index
          ) {
            <div
              class="p-chip flex flex-row items-center justify-between"
              [ngClass]="getCateChipClass(item)"
            >
              <div class="w-full" (click)="selectCate(item, true)">
                {{ item.label }}
                <!--
                 comment out beceause this count will contain variants count also which doesn't
                 align with the UI search result since UI only shows the main product.
                 If we have a better solution we can add this back
                  -->
                <!-- <span class="p-chip-qty ml-2">({{ item.count }})</span> -->
              </div>
              <i
                *ngIf="item.selected"
                class="pi pi-times"
                (click)="selectCate(item, false)"
              ></i>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="button-contant ml-6 mr-6">
      <p-button
        [style]="{ width: '280px' }"
        [label]="'Show results'"
        severity="contrast"
        [rounded]="true"
        (onClick)="onShowResultBtnClick()"
        [disabled]="disableShowResultBtn"
      ></p-button>
    </div>
  </div>
  <i
    class="close-button pi pi-chevron-left cursor-pointer"
    (click)="closeFilterPanel()"
  ></i>
</div>
