import { NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DividerModule } from 'primeng/divider';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SliderModule } from 'primeng/slider';
import { Subject } from 'rxjs';
import { AmmDataLabelValue } from '../../../components.global';
import { PRODUCTSORT } from '../../constants';
import { ProductFilter } from '../../models';
import { ProductFilterService, ProductService } from '../../services';
import { SearchProductStore } from '../../stores';
@Component({
  selector: 'amm-search-filter',
  standalone: true,
  imports: [
    ButtonModule,
    DividerModule,
    CheckboxModule,
    InputTextModule,
    SliderModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputNumberModule,
    ChipModule,
    NgxSliderModule,
  ],
  templateUrl: './amm-search-filter.component.html',
  styleUrl: './amm-search-filter.component.scss',
})
export class AmmSearchFilterComponent implements OnInit {
  @Input()
  searchKey?: string;

  @Input()
  fullScreen?: boolean;

  @Output()
  filter = new EventEmitter<ProductFilter>();
  @Output()
  showFilter = new EventEmitter<boolean>();

  public searchProductStore = inject(SearchProductStore);
  public productService: ProductService = inject(ProductService);
  private productFilterService: ProductFilterService =
    inject(ProductFilterService);
  filterCondition?: ProductFilter;
  searchCategoryCount: Array<AmmDataLabelValue> = [];
  get minPrice() {
    return this.searchProductStore.filteredMinPrice();
  }
  get maxPrice() {
    return this.searchProductStore.filteredMaxPrice();
  }
  set minSelected(value: number) {
    this.searchProductStore.setMinSelectedPrice(value);
  }
  get minSelected() {
    return this.searchProductStore.selectedMinPrice();
  }
  set maxSelected(value: number) {
    this.searchProductStore.setMaxSelectedPrice(value);
  }
  get maxSelected() {
    return this.searchProductStore.selectedMaxPrice();
  }
  filterRangeCount: number = 0;
  filtersCategory: Array<AmmDataLabelValue> = [];
  selectedFilter?: AmmDataLabelValue;
  filters: Array<AmmDataLabelValue> = [
    { label: PRODUCTSORT.RELEVANTLABEL, value: PRODUCTSORT.RELEVANT },
    { label: PRODUCTSORT.PRICELABEL, value: PRODUCTSORT.PRICEASC },
    { label: PRODUCTSORT.PRICEDESCLABEL, value: PRODUCTSORT.PRICEDESC },
  ];
  public disableClearBtn: boolean = true;
  public disableShowResultBtn: boolean = true;
  public sliderOptions = computed(() => {
    return <Options>{
      ceil: this.searchProductStore.filteredMaxPrice(),
      floor: this.searchProductStore.filteredMinPrice(),
      translate: (value: number): string => {
        return '$' + value;
      },
      noSwitching: true,
    };
  });
  private isFilterChanged$: Subject<boolean> = new Subject<boolean>();
  private isClearClicked: boolean = false;
  ngOnInit(): void {
    this.selectedFilter = this.filters[0];
    this.filterCondition = <ProductFilter>{};
    this.isFilterChanged$.subscribe(() => {
      this.disableClearBtn = false;
      this.disableShowResultBtn = false;
    });
    this.productFilterService.clearSearchFilter$.subscribe(() => {
      this.clear();
      this.productFilterService.searchFilter$.next(this.filterCondition);
    });
  }

  isFullScreen(): string {
    return this.fullScreen ? 'full-screen' : '';
  }

  getFilterButtonTitle(): string {
    //return 'Clear';
    return `Clear (${this.filterRangeCount + this.searchProductStore.selectedCategoriesCount()})`; //等后端做好改为这个
  }

  selectPrice(): void {
    this.isFilterChanged$.next(true);
    if (this.filterRangeCount == 0 && !this.isClearClicked) {
      this.filterRangeCount++;
    }
    this.isClearClicked = false;
    this.emitPrice();
  }

  closeFilterPanel(): void {
    this.showFilter.emit(false);
    this.searchProductStore.hideFilterPanel();
  }

  emitPrice(): void {
    if (this.filterCondition) {
      this.filterCondition.priceFrom = Math.round(this.minSelected * 100) / 100;
      this.filterCondition.priceTo = Math.round(this.maxSelected * 100) / 100;
    }
    this.filter.emit(this.filterCondition);
  }
  getCateChipClass(i: AmmDataLabelValue): string {
    return i.selected ? 'selected' : '';
  }

  selectCate(i: AmmDataLabelValue, selected: boolean): void {
    i.selected = selected;
    this.searchProductStore.toggleCategorySelection(i, selected);
    if (this.filterCondition) {
      this.filterCondition.categories =
        this.searchProductStore.selectedCategories();
    }
    this.isFilterChanged$.next(true);
    this.filter.emit(this.filterCondition);
  }

  sortMethod(f?: string): void {
    if (this.filterCondition) {
      this.filterCondition.sorts = f;
    }
    this.isFilterChanged$.next(true);
    this.filter.emit(this.filterCondition);
  }
  onShowResultBtnClick() {
    this.disableClearBtn = true;
    this.disableShowResultBtn = true;
    this.searchProductStore.resetCatorySelecttion();
    this.productFilterService.searchFilter$.next(this.filterCondition);
  }

  clear() {
    this.selectedFilter = this.filters[0];
    this.filterCondition = <ProductFilter>{};
    this.searchProductStore.setMaxSelectedPrice(this.maxPrice);
    this.searchProductStore.setMinSelectedPrice(this.minPrice);
    this.searchProductStore.resetCatorySelecttion();
    this.filterRangeCount = 0;
    this.disableClearBtn = true;
    this.disableShowResultBtn = false;
    this.isClearClicked = true;
  }
}
